

export class SurveyModel {
  icode: string;
  id: string;
  uId: string;
  title: string;
  maxOptionCharLength:number;
  clientId: string;
  isAnonymous: boolean;
  isClosed: boolean;
  responseVia: string;
  isMobile: boolean;
  isWeb: boolean;
  showInstructions: boolean;
  showTimer: boolean;
  linkId: string;
  kioskLinkId: string;
  submitButtonText: string;
  instructionButtonText: string;
  multiLanguage: boolean;
  languages: string[];
  useHrEmail: boolean;
  clientUId: string;
  clientName: string;
  headerImage: string;
  footerImage: string;
  introPageImage: string;
  thankYouPageImage: string;
  surveyClosedImage: string;
  initialInvitationEmailTemplate: string;
  reminderEmailTemplate: string;
  initialInvitationSMSTemplate: string;
  reminderSMSTemplate: string;
  emailFromAddress: string;
  emailFromAddressDisplayName: string;
  invitationEMailSubject: string;
  reminderEMailSubject: string;
  customizedMessage: boolean;
  customizedMessageText: string;
  useNewDesign: boolean;
  bannerPageTitle: string;
  bannerPageButtonText: string;
  instructionHeaderText: string;
  bannerPageTitleDescription: string;
  colorCode: string;
  instructionContents: InstructionC[];
  timerDuration: number;
  constructor() {
    this.languages = [];
    this.instructionContents = [];
  }
}

export class InstructionC
{
  text: string;
  language: string;
}
  
export class InvitationDetails {
  id: string;
  code: string;       // invitation code
  surveyUId: string;
  surveyCode: string;
  name: string;
  email: string;
  phone: string;
  surveyCompleted: boolean;
  status: string;
  employeeCode: string;
  department: string;
  location: string;
  companyName: string;
  reportsTo: string;
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: string;
  column6: string;
  column7: string;
  column8: string;
  column9: string;
  column10: string;
}

export class ClientModel {
  id: string;
  uId: string;
  name: string;
  clientname: string;
  adminName: string;
  mobile: string;
  email: string;
}

export class UserModel {
  id: string;
  uId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNo: number;
}

export class User {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
}


export class SurveyQuestionSet {
  clientUId: string; order: any;
  invitationCode: string;
  id: string;
  code: string;
  questions: SurveyQuestionModel[];
  surveyLinkId: string;
  surveyUId: string;
  constructor() {
    this.questions = [];
  }

}

// for SuerveQuestion Model
export class SurveyQuestionModel {
  code: string;
  order: number;
  pageNumber: number;
  dependentOn: number;
  type: string;
  sectionTitle: string;
  questionStyle: string;
  noOfOptions: number;
  maxSelect: number;
  minSelect: number;
  texts: QuestionText[];
  images: QuestionImage[];
  options: QuestionOption[];
  xModelTag: string;
  driverStatement: string;
  characterLimit: string;
  language: string;
  response: string;
  otherResponseText: string;
  icode: string;
  ifPreviousAnswer: string;
  constructor() {
    this.type = '';
    this.response = '';
    this.texts = [];
    this.options = [];
    this.images = [];
  }
}

export class QuestionText {
  language: string;
  text: string;
  ifPreviousAnswer: string;
}

export class QuestionImage {
  language: string;
  image: string;

}

export class QuestionOption {
  order: number;
  ifPreviousAnswer: string;
  languageOptions: LanguageOption[];

  constructor() {
    this.languageOptions = [];
  }
}

export class LanguageOption {
  ifPreviousAnswer: string;
  code: string;
  language: string;
  text: string;
}


export class SurveyInstructions {
  id: string;
  dType: string;
  pageNumber: string;
  surveyUId: string;
  time: 0;
  instruction: string;
}